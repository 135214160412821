<template>
  <div class="app-main-content">
    <div class="header">
      <div class="title">
        <template v-if="$hasP('P_SETTING')">
          <div class="nav-title">
            <edp-input
              size="custom"
              v-model="proInfo.project_name"
              class="nav-title-input"
              :db="dbs.prjCore"
              field-name="project_name"
              :prj-oid="prj_oid"
              :oid="prj_oid"
            ></edp-input>
          </div>
          <!-- 一个对齐input内容的不可见div -->
          <div class="nav-title-hidden">{{ proInfo.project_name }}{{ titleUpdate }}</div>
        </template>
        <div style="font-weight: 700; margin-right: 10px;color: #333;" v-else>
          {{proInfo.project_name}}
        </div>
      </div>
      <edp-select
        :disabled="!$hasP('P_SETTING')"
        :skin="`status ${proInfo.business_status === '100' ? 'yellow' : proInfo.business_status === '500' ? 'green' : proInfo.business_status === '1000' ? 'gray' : ''}`"
        v-model="proInfo.business_status"
        :db="dbs.prjCore"
        field-name="business_status"
        :oid="prj_oid"
        :prj-oid="prj_oid"
        cat-type="project_bstatus"
      >
      </edp-select>
    </div>
    <div class="content">
      <div class="row-1">
        <div class="row-item-1 item_1">
          <div class="title">项目信息</div>
          <div class="info">
            <div class="item">
              <img :src="proInfo.admins[0]?.pic ?? `${$appsettings.imgPrefix}default_avatar.png`" alt="">
              <div class="user">
                <div class="name">
                  <edp-select
                    v-if="$hasP('P_SETTING')"
                    size="mini"
                    style="width: 150px;"
                    v-model="proInfo.project_admin"
                    :db="dbs.prjCore"
                    field-name="project_admin"
                    :prj-oid="prj_oid"
                    :oid="prj_oid"
                    account="prj"
                  ></edp-select>
                  <template v-else>{{proInfo.admins?.[0]?.name}}</template>
                </div>
                <div class="job">项目负责人</div>
              </div>
            </div>
            <div class="item">
              <img :src="`${$appsettings.imgPrefix}task_status_blue.png`" alt="">
              <div class="user">
                <div class="name a-style-ellipsis">{{ proInfo.workitems_new }}</div>
                <div class="job">未开始</div>
              </div>
              <img :src="`${$appsettings.imgPrefix}task_status_yellow.png`" alt="">
              <div class="user">
                <div class="name a-style-ellipsis">{{ proInfo.workitems_running }}</div>
                <div class="job">进行中</div>
              </div>
              <img :src="`${$appsettings.imgPrefix}task_status_green.png`" alt="">
              <div class="user">
                <div class="name a-style-ellipsis">{{ proInfo.workitems_finish }}</div>
                <div class="job">已完成</div>
              </div>
            </div>
            <div class="item">
              <img :src="`${$appsettings.imgPrefix}shijian.png`" alt="">
              <div class="user">
                <div class="name" style="width: 100%">
                  <edp-range-picker
                    v-if="$hasP('P_SETTING')"
                    :value="[proInfo.schedule_start_date, proInfo.schedule_end_date]"
                    @change="aRangePicker"
                  ></edp-range-picker>
                  <span style="font-size: 14px" v-else>
                    {{proInfo.schedule_start_date}} ~ {{proInfo.schedule_end_date}}
                  </span>
                </div>
                <div class="job">项目计划周期</div>
              </div>
            </div>
            <div class="item">
              <img :src="`${$appsettings.imgPrefix}jingdu.png`" alt="">
              <div class="user">
                <div class="name progress">
                  <a-progress strokeColor="#24B47E" :percent="proInfo.compelete_percent - 0" />
                </div>
                <div class="job">项目计划进度</div>
              </div>
            </div>
          </div>
        </div>
        <div class="row-item-2 item_1">
          <div class="title">项目属性</div>
          <div class="num">共5个项目属性</div>
          <div class="info">
            <div class="item">
              <div class="name">项目编号</div>
              <div class="status">
                <edp-input
                  v-if="$hasP('P_SETTING')"
                  v-model="proInfo.project_code"
                  :db="dbs.prjCore"
                  field-name="project_code"
                  :oid="prj_oid"
                  :prj-oid="prj_oid"
                >
                </edp-input>
                <template v-else>{{proInfo.project_code}}</template>
              </div>
            </div>
            <div class="item">
              <div class="name">项目负责人</div>
              <div class="status">
                <edp-select
                  v-if="$hasP('P_SETTING')"
                  :db="dbs.prjCore"
                  field-name="project_admin"
                  v-model="proInfo.project_admin"
                  :prj-oid="prj_oid"
                  :oid="prj_oid"
                  account="prj"
                ></edp-select>
                <template v-else>{{proInfo.admins?.[0]?.name}}</template>
              </div>
            </div>
            <div class="item">
              <div class="name">项目年份</div>
              <div class="status">
                <edp-year-picker
                  v-if="$hasP('P_SETTING')"
                  v-model="proInfo.project_year"
                  :db="dbs.prjCore"
                  field-name="project_year"
                  :prj-oid="prj_oid"
                  :oid="prj_oid"
                  mode="year"
                  valueFormat="YYYY"
                  format="YYYY"
                >
                </edp-year-picker>
                <template v-else>{{proInfo.project_year}}</template>
              </div>
            </div>
            <div class="item">
              <div class="name">项目类型</div>
              <div class="status">
                <!--                {{ proInfo.project_type_name }}-->
                <edp-select
                  v-if="$hasP('P_SETTING')"
                  v-model="proInfo.project_type"
                  :db="dbs.prjCore"
                  field-name="project_type"
                  :oid="prj_oid"
                  :prj-oid="prj_oid"
                  cat-type="project_type"
                >
                </edp-select>
                <template v-else>{{proInfo.project_type_name}}</template>
              </div>
            </div>
            <div class="item">
              <div class="name">创建日期</div>
              <div class="status">{{ proInfo.create_time }}</div>
            </div>
          </div>
        </div>
        <div class="row-item-3 item_2">
          <div class="title">项目公告</div>
          <div class="content">
            <edp-textarea
              v-if="$hasP('P_SETTING')"
              style="height: 230px;"
              v-model="proInfo.project_notice"
              :db="dbs.prjCore"
              :prj-oid="prj_oid"
              :oid="prj_oid"
              field-name="project_notice"
            ></edp-textarea>
            <template v-else>{{proInfo.project_notice}}</template>
          </div>
        </div>
      </div>
      <div class="row-2">
        <div class="row-item-1 item_3">
          <div class="title">项目里程碑</div>
          <div class="num">共{{ issue_totalcount }}项</div>
          <projectMilestoneSteps :prj-oid="prj_oid">
            <template slot="title">里程碑时间轴{{ proInfo.complete_percent }}%</template>
          </projectMilestoneSteps>
        </div>
      </div>
      <div class="row-3">
        <div class="row-item-1">
          <div class="item_4">
            <div class="title">上线倒计时</div>
            <div class="num"></div>
            <div class="content">
              还剩<span class="num">{{ proInfo.countdown }}</span>天
            </div>
          </div>
          <div class="item_4">
            <div class="title">待处理需求</div>
            <div class="num">总共{{ proInfo.req_totalcount }}个</div>
            <div class="content">
              <span class="num">{{ proInfo.req_todo }}</span>个
            </div>
          </div>
          <div class="item_4">
            <div class="title">待处理任务</div>
            <div class="num">总共{{ proInfo.issue_totalcount }}个</div>
            <div class="content">
              <span class="num">{{ proInfo.issue_todo }}</span>个
            </div>
          </div>
        </div>
        <div class="row-item-2 item_5">
          <div class="title">待交付统计</div>
          <div class="num">共{{ proInfo.delivery_todo }}个</div>
          <echarts id="item_5_canvas" :option="pieData" :style="{width: '100%', height: '150px'}"></echarts>
        </div>
      </div>
      <div class="row-4">
        <div class="row-item-1 item_6">
          <div class="title">阶段统计</div>
          <div class="num">共{{ proInfo.phase_running }}个进行中</div>
          <a-table class="item_6_table" :data-source="proPhaseList" rowKey="oid" :pagination="false">
            <a-table-column title="阶段名称" data-index="phase_name" />
            <a-table-column title="工作项" data-index="workitems_finish">
              <template slot-scope="scope, row">
                {{ scope }}/{{ row.workitems_totalcount }}
              </template>
            </a-table-column>
            <!--            <a-table-column title="需求" data-index="req_finish">-->
            <!--              <template slot-scope="scope, row">-->
            <!--                {{scope}}/{{row.req_totalcount}}-->
            <!--              </template>-->
            <!--            </a-table-column>-->
            <a-table-column title="计划开始" data-index="schedule_start_date" />
            <a-table-column title="结束时间" data-index="schedule_end_date" />
            <a-table-column title="当前状态" data-index="phase_status_name" width="100px">
              <template v-slot:default="_, row">
                <div :class="{
                    'edp-status': true,
                    blue: row.phase_status === '0',
                    grey: row.phase_status === '90',
                    yellow: ['10', '30', '50', '70'].includes(row.phase_status),
                    green: ['20', '40', '60', '80'].includes(row.phase_status)}"
                >
                  {{ row.phase_status_name }}
                </div>
              </template>
            </a-table-column>
          </a-table>
        </div>
        <div class="row-item-2 item_6">
          <div class="title">工作项统计</div>
          <div class="num">共{{ proInfo.workitems_total }}个工作项</div>
          <echarts id="item_6_canvas" :option="lineData" :style="{width: '100%', height: '250px'}"></echarts>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import projectMilestoneSteps from "@/components/projectMilestoneSteps";
import echarts from "@/components/echarts";
import {fetch} from "@/utils/request";
import {FieldUpdateDb} from "@/utils/FieldUpdate.js";
import EdpSelect from "@/components/e-form/edp-select.vue";
import EdpInput from "@/components/e-form/edp-input.vue";
import EdpYearPicker from "@/components/e-form/edp-year-picker.vue";
import EdpRangePicker from "@/components/e-form/edp-range-picker.vue";
import EdpTextarea from "@/components/e-form/edp-textarea.vue";

const pieData = {
  tooltip: {
    trigger: 'item'
  },
  legend: {
    top: '40%',
    left: '15%'
  },
  series: [
    {
      name: '',
      type: 'pie',
      top: '-13%',
      radius: ['40%', '70%'],
      avoidLabelOverlap: false,
      color: ['#24B47E', '#0088FF'],
      label: {
        show: false
      },
      emphasis: {
        label: {
          show: false
        }
      },
      labelLine: {
        show: false
      },
      data: [
        { value: 0, name: '已交付' },
        { value: 0, name: '应交付' }
      ]
    }
  ]
};
const lineData = {
  tooltip: {
    trigger: 'axis',
    axisPointer: {
      // Use axis to trigger tooltip
      type: 'shadow' // 'shadow' as default; can also be 'line' or 'shadow'
    }
  },
  legend: {},
  grid: {
    left: '3%',
    right: '4%',
    bottom: '3%',
    containLabel: true
  },
  xAxis: {
    type: 'category',
    // '需求'
    data: ['任务', '里程碑'],
    axisLabel: {
      textStyle: {
        show: true,
        fontFamily: '微软雅黑',
        color: "#4D4D4D",
        fontSize: '16',
      }
    }
  },
  yAxis: {
    type: 'value',
    axisLabel: {
      textStyle: {
        show: true,
        fontFamily: '微软雅黑',
        color: "#4D4D4D",
        fontSize: '12',
      }
    }
  },
  series: [
    {
      name: '未开始',
      type: 'bar',
      stack: 'total',
      color: '#0088FF',
      label: {
        show: false
      },
      emphasis: {
        focus: 'series'
      },
      data: [0, 0, 0]
    },
    {
      name: '进行中',
      type: 'bar',
      stack: 'total',
      color: '#FBB70E',
      label: {
        show: false
      },
      data: [0, 0, 0]
    },
    {
      name: '已完成',
      type: 'bar',
      stack: 'total',
      color: '#24B47E',
      label: {
        show: false
      },
      emphasis: {
        focus: 'series'
      },
      data: [0, 0, 0]
    }
  ]
};
export default {
  name: "projectOverView",
  data() {
    return {
      dbs: {
        prjCore: new FieldUpdateDb("prj", "prj_core")
      },
      pieData,
      lineData,
      prj_oid: '',
      proInfo: {
        admins: []
      },
      issue_totalcount: '',
      proPhaseList: [],
      titleUpdate: '',
      options: []
    }
  },
  components: {
    EdpTextarea,
    EdpRangePicker,
    EdpYearPicker,
    EdpInput,
    EdpSelect,
    projectMilestoneSteps,
    echarts
  },
  created() {
    this.prj_oid = this.$route.query.prj_oid
  },
  mounted() {
    if (this.prj_oid) {
      this.getProInfo() // 获取项目详情
      this.getProMilepostSta() // 获取里程碑总数和进度
      this.getProSta() // 获取项目统计
      this.getProPhaseList() // 获取项目阶段统计
      this.getMemberList()
    }
  },
  methods: {
    // 获取项目详情
    getProInfo() {
      fetch('post', '/prj/info', {
        oid: this.prj_oid
      }).then((res) => {
        if (res.status === 0) {
          let data = res.data
          this.proInfo = {
            ...this.proInfo,
            ...data
          }
        } else {
          this.$message.error(res.message)
        }
      })
    },
    // 获取里程碑总数和进度
    getProMilepostSta() {
      fetch('post', '/prj/issue/milepost/sta', {
        prjoid: this.prj_oid
      }).then((res) => {
        if (res.status === 0) {
          let { complete_percent, issue_totalcount } = res.data
          this.issue_totalcount = issue_totalcount //里程碑总数
          this.proInfo = {
            ...this.proInfo,
            complete_percent
          }
        }
      })
    },
    // 获取项目统计
    getProSta() {
      fetch('post', '/prj/sta', {
        oid: this.prj_oid
      }).then((res) => {
        if (res.status === 0) {
          let data = res.data
          console.log("图表", res.data);
          this.proInfo = {
            ...this.proInfo,
            ...data
          }
          pieData.series[0].data = data.deliverysta // 饼状图
          lineData.series[0].data = data.notstarted // 未开始
          lineData.series[1].data = data.running // 进行中
          lineData.series[2].data = data.finish // 已完成
        }
      })
    },
    // 获取项目阶段统计
    getProPhaseList() {
      fetch('post', '/prj/phase/retrieve/byprj', {
        prjoid: this.prj_oid
      }).then((res) => {
        if (res.status === 0) {
          let data = res.data
          this.proPhaseList = data
          console.log(data);
        }
      })
    },
    getMemberList() {
      fetch('post', '/prj/userlist/list', {
        prjoid: this.$route.query.prj_oid
      }).then((res) => {
        if (res.status === 0) {
          this.options = res.data
        }
      })
    },
    async aRangePicker(dateString, preString) {
      await this.$updateOnefield('prj', 'prj_core', 'schedule_start_date', dateString[0], this.prj_oid, this.prj_oid, preString[0], dateString[0])
      await this.$updateOnefield('prj', 'prj_core', 'schedule_end_date', dateString[1], this.prj_oid, this.prj_oid, preString[1], dateString[1])
      this.getProInfo()
    },
    titleCompositionUpdate(e) {
      console.log(e);
      if (e) {
        this.titleUpdate = e.data
      }
    },
    titleCompositionEnd(e) {
      console.log(e);
      if (e) {
        this.titleUpdate = e.data
      }
    }
  }
}
</script>

<style scoped lang="less">
.row-1 {
  margin-top: 20px;
  display: flex;
  .row-item-1 {
    flex-shrink: 0;
    width: 450px;
  }
  .row-item-2 {
    flex-shrink: 0;
    width: 450px;
    margin: 0 15px;
  }
  .row-item-3 {
    flex: 1;
  }
}
.row-2 {
  margin-top: 15px;
  .row-item-1 {
    width: 100%;
  }
}
.row-3 {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: 15px;
  margin-top: 15px;
  .row-item-1 {
    display: grid;
    grid-column-gap: 15px;
    grid-template-columns: repeat(3, 1fr);
  }
}
.row-4 {
  margin-top: 15px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: 15px;
}
.header {
  width: 100%;
  height: 60px;
  background: rgba(255, 255, 255, 1);
  padding: 0 20px;
  display: flex;
  align-items: center;
  box-shadow: 0 2px 10px 0 rgba(182, 182, 182, 0.50);

  .title {
    width: auto;
    min-width: 120px;
    height: 32px;
    line-height: 32px;
    position: relative;

    .nav-title {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 1;

      .nav-title-input {
        width: 100%;
        font-size: 20px;
        font-weight: 700;
        color: #4D4D4D;
        border-color: transparent;
      }

      &:hover {
        .nav-title-input {
          border-color: #C2C9CF;
        }
      }
    }

    .nav-title-hidden {
      height: 0;
      overflow: hidden;
      white-space: nowrap;
      // 对齐input所有会影响宽度的样式
      padding: 0 11px;
      font-size: 20px;
      font-weight: 700;
    }
  }

  .header-select {
    width: 75px;
    margin-left: 8px;

    /deep/ .ant-select-selection {
      border-radius: 20px;
      border: 1px solid #0088FF;
      font-size: 13px;
      color: #0088FF;
      font-weight: 700;
      height: 22px;
      line-height: 22px;
      box-sizing: border-box;

      .ant-select-selection__rendered {
        line-height: 22px;
        margin: 0 21px 0 8px;

        .ant-select-selection-selected-value {
          line-height: 22px;
        }
      }
    }

    /deep/ .ant-select-arrow {
      color: #0088FF;
      right: 10px;
    }
  }
}

.content {
  position: relative;
  width: 100%;

  .item_1, .item_2, .item_3, .item_4, .item_5, .item_6 {
    padding: 25px 20px 0;
    box-shadow: 0 2px 10px 0 rgba(182, 182, 182, 0.50);

    .title {
      font-size: 18px;
      font-weight: 700;
    }
  }

  .item_1 {
    width: 450px;
    height: 330px;
    background: rgba(255, 255, 255, 1);
    min-width: 450px;
    top: 14px;

    .num {
      font-size: 12px;
      color: #A7A7A7;
    }

    .info {

      .item {
        margin-top: 16px;
        display: flex;
        align-items: center;

        .user {
          font-weight: 500;
          font-size: 16px;
          color: #4D4D4D;
          margin-left: 11px;

          .job {
            font-size: 12px;
            color: #A7A7A7;
          }

          .progress {
            width: 298px;

            /deep/ .ant-progress-inner {
              background-color: #E8E8E8;
            }

            /deep/ .ant-progress-text {
              color: #4D4D4D;
            }
          }
        }

        .name {
          width: 100px;

          .onefield.ant-select {
            width: 100%;
          }

          /deep/ .onefield {
            &.ant-calendar-picker {
              width: 230px;

              .ant-calendar-range-picker-separator {
                margin-top: 4px;
              }

              .ant-calendar-range-picker-input {
                width: 34%;
                padding: 0;
                text-align: left;
              }
            }
          }
        }

        .status {
          color: #333;
          margin-left: 40px;
          font-size: 14px;

          .onefield.ant-select {
            width: 200px;
          }
        }

        > img {
          width: 30px;
          height: 30px;
          display: inline-block;
          border-radius: 50%;
          object-fit: cover;
        }
      }

    }
  }

  .item_2 {
    top: 14px;
    width: 932px;
    height: 330px;
    background: rgba(255, 255, 255, 1);
    padding: 25px 20px 10px;

    .content {
      margin-top: 27px;
      line-height: 1.8;
      overflow: hidden;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 8; //设置几行省略
    }
  }

  .item_3 {
    height: 310px;
    background: rgba(255, 255, 255, 1);

    .num {
      font-size: 12px;
      color: #A7A7A7;
      margin-bottom: 10px;
    }
  }

  .item_4 {
    height: 224px;
    background: rgba(255, 255, 255, 1);

    & > .num {
      height: 18px;
      font-size: 12px;
      font-weight: 400;
      color: #A7A7A7;
    }

    .content {
      font-size: 14px;
      font-weight: 100;
      text-align: center;
      line-height: 139px;

      .num {
        font-size: 48px;
        font-weight: 700;
        padding: 0 8px;
      }
    }
  }

  .item_5 {
    height: 224px;
    background: rgba(255, 255, 255, 1);

    & > .num {
      height: 18px;
      font-size: 12px;
      font-weight: 400;
      color: #A7A7A7;
    }
  }

  .item_6 {
    min-height: 320px;
    background: rgba(255, 255, 255, 1);

    & > .num {
      height: 18px;
      font-size: 12px;
      font-weight: 400;
      color: #A7A7A7;
    }

    .item_6_table {
      margin-top: 20px;
    }
  }
}
</style>
