<template>
  <div class="edp-range-picker">
    <div class="content" @click="open = true">
      <template v-if="value[0] || value[1]">
        <div class="text">
          {{value[0] || '请选择开始'}}
        </div>
        <div class="text center">~</div>
        <div class="text">
          {{value[1] || '请选择结束'}}
        </div>
      </template>
      <template v-else>
        <div class="text">请选择日期</div>
      </template>
    </div>
    <a-range-picker
      inputReadOnly
      valueFormat="YYYY/MM/DD"
      format="YYYY/MM/DD"
      :value="value"
      v-bind="$attrs"
      @change="change"
      :open="open"
      @openChange="openChange"
    >
    </a-range-picker>
  </div>
</template>

<script>
export default {
  emits: ['change'],
  props: {
    value: {
      required: true,
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      open: false,
      preValue: []
    }
  },
  watch: {
    value: {
      immediate: true,
      handler(val) {
        if (typeof val !== typeof this.preValue || (Array.isArray(val) && val.join('-') !== this.preValue.join('-'))) {
          this.preValue = val
        }
      }
    }
  },
  methods: {
    openChange(open) {
      this.open = open
    },
    change(val, str) {
      this.$emit("change", str, this.preValue)
    }
  }
}
</script>

<style lang="less" scoped>
.edp-range-picker {
  width: 200px;
  position: relative;
  .content {
    width: 100%;
    height: 32px;
    display: flex;
    align-items: center;
    border: 1px solid rgba(0,0,0,0);
    border-radius: 4px;
    transition: .3s all;
    &:hover {
      border-color: var(--primary-color);
      padding-left: 8px;
    }
    .text {
      font-size: 14px;
      line-height: 1;
      &.center {
        margin: 0 5px;
      }
    }
  }
  /deep/ .ant-calendar-picker {
    opacity: 0;
    position: absolute;
    left: 0;
    top: 0;
    width: 0;
    height: 0;
    .ant-calendar-range-picker-input {
      padding: 0;
    }
    .ant-input {
      padding: 0;
      border: none;
    }
  }
}
</style>