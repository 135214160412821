<template>
  <a-date-picker
    mode="year"
    @panelChange="panelChange"
    @openChange="openChange"
    class="edp-year-picker"
    v-bind="$attrs"
    v-model="tempValue"
    :open="open"
    format="YYYY"
    valueFormat="YYYY"
    inputReadOnly
    placeholder="请选择年份"
  >
  </a-date-picker>
</template>

<script>
import edpFormMixins from "@/components/e-form/edpFormMixins.js";

export default {
  mixins: [edpFormMixins],
  data() {
    return {
      open: false
    }
  },
  methods: {
    panelChange(value) {
      this.tempValue = value.format("YYYY")
      this.changeEvent()
      this.open = false
    },
    openChange(status) {
      this.open = status
    }
  }
}
</script>

<style lang="less" scoped>
.edp-year-picker {
  width: 200px;
  /deep/ .ant-calendar-picker-input {
    border-color: rgba(0,0,0,0);
    padding-left: 0;
    color: #303030;
    &:hover, &:focus {
      border: 1px solid #0088FF;
      padding-left: 8px;
    }
  }
  /deep/ .ant-calendar-picker-clear, /deep/ .ant-calendar-picker-icon {
    display: none;
  }
}
</style>