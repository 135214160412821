<template>
  <div class="project-milestone-steps">
    <div class="title">
      <slot name="title"></slot>
    </div>
    <div class="steps-content" v-if="list.length > 0">
      <div class="steps-item-fist d-flex">
        <div class="icon"></div>
      </div>
      <div class="steps-item d-flex" v-for="(item, index) in list" :key="index">
        <a-tooltip>
          <template slot="title">{{ item.issue_name }}</template>
          <div :class="['name', 'a-style-ellipsis', item.issue_status === '100' ? 'done' : '']">{{ item.issue_name }}</div>
        </a-tooltip>
        <div :class="['icon', item.issue_status === '100' ? 'done' : '']"></div>
        <div class="info d-flex">
          <div class="status">状态：{{ item.issue_status_name }}</div>
          <div>{{ item.schedule_end_date }}</div>
<!--          <div>{{ item.actual_end_date }}</div>-->
<!--          <div>{{ item.schedule_end_date }}</div>-->
          <div class="warning" v-if="item.later_days">
            落后{{ item.later_days }}天
          </div>
        </div>
      </div>
      <div class="steps-item-last d-flex">
        <div class="icon"></div>
      </div>
    </div>
    <edp-empty v-else />
  </div>
</template>

<script>
import {fetch} from "@/utils/request";

export default {
  name: "projectMilestoneSteps",
  data() {
    return {
      list: []
    }
  },
  props: {
    prjOid: {  // 项目oid 必填
      type: String
    },
    getChildList: { // 传给父级列表数据减少请求 非必填
      type: Function,
      default: function () {}
    }
  },
  mounted() {
    if (this.prjOid) this.getMilepostList()
  },
  methods: {
    getMilepostList() {
      fetch('post', '/prj/issue/milepost', {
        prjoid: this.prjOid
      }).then((res) => {
        if (res.status === 0) {
          let data = res.data
          // console.log(data);
          this.list = data
          this.getChildList(data) // 有的页面需要里程碑列表 无需重复调用接口
        }
      })
    }
  }
}
</script>

<style scoped lang="less">
.project-milestone-steps {
  //padding: 18px 0;

  .title {
    font-size: 14px;
    margin-bottom: 30px;
  }

  .steps-content {
    //position: relative;
    display: flex;
    align-items: center;
    overflow-x: auto;

    .steps-item-fist {
      min-height: 160px;
      position: relative;
      margin-right: 112px;
      flex-shrink: 0;

      .icon {
        width: 4px;
        height: 4px;
        background-color: #FFFFFF;
        border: 5px solid #CACACA;
        border-radius: 20px;
        position: absolute;
        top: 24%;
        transform: translateY(-50%);
        box-sizing: content-box;
      }

      &::before {
        position: absolute;
        width: 149px;
        height: 1px;
        background: #CACACA;
        content: '';
        display: block;
        left: 14px;
        top: 24%;
        transform: translate(0, -50%);
      }
    }

    .steps-item {
      width: 130px;
      min-height: 160px;
      margin-right: 57px;
      font-size: 12px;
      color: #909090;
      flex-direction: column;
      align-items: center;
      position: relative;
      flex-shrink: 0;

      .name {
        max-width: 126px;
        font-size: 12px;
        color: #4397E7;
        border-radius: 20px;
        border: 1px solid #4397E7;
        padding: 0 8px;
        text-align: center;
        height: 20px;
        box-sizing: border-box;
      }

      .icon {
        width: 7px;
        height: 7px;
        background: #FFF;
        border: 2px solid #4397E7;
        margin: 15px 0;
        transform: rotate(45deg);
      }

      .info {
        text-align: center;
        flex-direction: column;
        align-items: center;

        .warning {
          font-size: 14px;
          border-radius: 2px 2px 2px 2px;
          background: #FCE5E5;
          color: #FF0000;
          padding: 1px 8px;
          margin-top: 8px;
        }
      }

      &::before {
        position: absolute;
        width: 160px;
        height: 1px;
        background: #CACACA;
        content: '';
        display: block;
        left: 78px;
        top: 24%;
        transform: translate(0, -50%);
      }

      & > .name.done {
        color: #24B47E;
        border-color: #24B47E;
      }

      & > .icon.done {
        border-color: #24B47E;
      }
    }

    .steps-item-last {
      min-height: 160px;
      position: relative;
      margin-left: 40px;
      flex-shrink: 0;

      .icon {
        width: 14px;
        height: 14px;
        background: #CACACA;
        border-radius: 20px;
        position: absolute;
        top: 24%;
        transform: translateY(-50%);
      }
    }
  }
}
</style>
